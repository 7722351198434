import { createRouter, createWebHistory } from 'vue-router';
import height from '@/views/QueryHeight.vue';


const routes = [
    { path: '/height', component: height },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
