<template>
  <br>
  <div class="container">
    <!-- 第一个区域: 必读和警告 -->
    <div class="card">
      <div v-if="!isSectionCollapsed.section1" class="card-body">
        <!-- 必读部分 -->
        <div class="nested-card">
          <div class="card-header" @click="toggleNestedSection('mustRead')">
            <div class="header-text">
              <h3>必读</h3>
            </div>
            <div class="header-icon">
              <i :class="isNestedSectionCollapsed.mustRead ? 'fas fa-chevron-right' : 'fas fa-chevron-down'"></i>
            </div>
          </div>
          <p class="small-text">点我展开</p>
          <transition name="fade">
            <div v-if="!isNestedSectionCollapsed.mustRead" class="card-body detailed-text">
              <p>有问题请加群联系管理员</p>
              <p>购买卡券请进群</p>
              <p>QQ群：112314853</p>
              <p>点击最下方链接或者扫描二维码加入群聊</p>
            </div>
          </transition>
        </div>

        <!-- 警告部分 -->
        <div class="nested-card">
          <div class="card-header" @click="toggleNestedSection('warning')">
            <div class="header-text">
              <h3>警告</h3>
            </div>
            <div class="header-icon">
              <i :class="isNestedSectionCollapsed.warning ? 'fas fa-chevron-right' : 'fas fa-chevron-down'"></i>
            </div>
          </div>
          <p class="small-text">点我展开</p>
          <transition name="fade">
            <div v-if="!isNestedSectionCollapsed.warning" class="card-body detailed-text">
              <p>国服全渠道首次使用需要添加测量员好友 后续不需要！<br>
                国服全渠道服测完禁止拉黑测量员！<br>
                拉黑后数据将无法更新最新身高！！</p>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- 查询身高 -->
    <div class="card">
      <div class="card-header">
        <div class="header-text">
          <h3>查询身高</h3>
        </div>
      </div>
      <div class="card-body">
        <form @submit.prevent="queryHeight">
          <div class="form-group">
            <label for="key">卡券:</label>
            <input type="text" id="key" v-model="key" placeholder="请输入卡券" required />
          </div>
          <div class="form-group">
            <label for="userId">游戏ID:</label>
            <input type="text" id="userId" v-model="userId" placeholder="请输入游戏ID" required />
          </div>
          <button type="submit" :disabled="loading" class="modal-button">查询</button>
        </form>
        <p v-if="loading">加载中...</p>
      </div>
    </div>

    <!-- 模态框 -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal">
        <div class="modal-header">
          <h3>查询结果</h3>
          <button @click="closeModal" class="modal-close-button">×</button>
        </div>
        <div class="modal-body">
          <p :class="{ success: success, error: !success }">{{ message }}</p>
          <div v-if="parsedData" class="modal-content">
            <div class="info-section">
              <strong>身高资料 :</strong>
              <ul>
                <li><strong>体型值 : </strong> {{ parsedData['体型值'] || '未定义' }}</li>
                <li><strong>身高值 : </strong> {{ parsedData['身高值'] || '未定义' }}</li>
                <li><strong>最高身高 : </strong> {{ parsedData['最高身高'] || '未定义' }}</li>
                <li><strong>当前身高 : ◈</strong> {{ parsedData['当前身高'] || '未定义' }}</li>
                <li><strong>最矮身高 : </strong> {{ parsedData['最矮身高'] || '未定义' }}</li>
              </ul>
            </div>
            <div class="info-section">
              <strong>其他资料 :</strong>
              <ul>
                <li><strong>发型 : </strong> {{ parsedData['发型'] || '未定义' }}</li>
                <li><strong>背饰 : </strong> {{ parsedData['背饰'] || '未定义' }}</li>
                <li><strong>项链 : </strong> {{ parsedData['项链'] || '未定义' }}</li>
                <li><strong>头饰 : </strong> {{ parsedData['头饰'] || '未定义' }}</li>
                <li><strong>面具 : </strong> {{ parsedData['面具'] || '未定义' }}</li>
                <li><strong>鞋子 : </strong> {{ parsedData['鞋子'] || '未定义' }}</li>
                <li><strong>斗篷 : </strong> {{ parsedData['斗篷'] || '未定义' }}</li>
                <li><strong>裤子 : </strong> {{ parsedData['裤子'] || '未定义' }}</li>
              </ul>
            </div>
            <div class="info-section">
              <strong>卡券点数 :</strong>
              <p>{{ cardPoints === null || cardPoints === undefined ? '未定义' : cardPoints }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新添加的区域 -->
    <div class="card">
      <div class="card-header">
        <div class="header-text">
          <h3>加入我们的群聊</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="join-group">
          <p>扫描下方二维码或点击链接加入我们的群聊：</p>
          <img src="@/assets/qrcode_1726386484692.jpg" alt="群聊二维码" class="group-qrcode" />
          <p>
            <a :href="groupLink" target="_blank" class="join-link">点击这里加入群聊</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'QueryHeight',
  data() {
    return {
      isSectionCollapsed: {
        section1: false,
      },
      isNestedSectionCollapsed: {
        mustRead: true,
        warning: true,
      },
      key: '',
      userId: '',
      message: '',
      parsedData: null,
      success: false,
      loading: false,
      showModal: false,
      groupLink: 'http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=GspZImdNAl4gN19Xoirj7hzINCsrdD7a&authKey=MsMIr8gEgGvjMUenHcXprJjwvZIoDAfuyYYoeajTTrX1%2BjvD3Mrx8VibhwEapeGW&noverify=0&group_code=112314853',
      cardPoints: null,
    };
  },
  methods: {
    toggleNestedSection(section) {
      this.isNestedSectionCollapsed[section] = !this.isNestedSectionCollapsed[section];
    },
    async queryHeight() {
      this.loading = true;
      this.message = '';
      this.parsedData = null;
      this.success = false;
      this.cardPoints = null; // 重置卡券点数
      this.showModal = false;

      try {
        const response = await axios.post('http://124.71.230.206:8080/api/query', null, {
          params: { key: this.key, userId: this.userId }
        });

        console.log('API Response:', response.data);

        if (response.data.code === 200) {
          this.parsedData = this.parseData(response.data.data);
          this.message = response.data.message;
          this.success = true;
          this.cardPoints = response.data['卡券点数']; // 直接赋值，不处理为 '未定义'
        } else {
          this.message = response.data.message;
          this.success = false;
        }
      } catch (error) {
        this.message = '查询失败，请重试';
        this.success = false;
        console.error('查询出错:', error);
      } finally {
        this.loading = false;
        this.showModal = true;
      }
    }
    ,
    closeModal() {
      this.showModal = false;
    },
    parseData(data) {
      const parsed = {};
      const lines = data.split('\n');
      lines.forEach(line => {
        const [key, value] = line.split(':');
        if (key && value) {
          parsed[key.trim()] = value.trim();
        }
      });
      return parsed;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
}

.card {
  margin-bottom: 20px;
}

.card-header {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.card-body {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.detailed-text {
  font-size: 14px;
  line-height: 1.6;
}

.small-text {
  font-size: 12px;
}

.header-text h3 {
  margin: 0;
}

.header-icon {
  margin-left: auto;
}

.join-group {
  text-align: center;
}

.group-qrcode {
  max-width: 200px;
  height: auto;
  margin-top: 10px;
}

.join-link {
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
}

.join-link:hover {
  text-decoration: underline;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  padding: 20px;
}

.modal-header {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-content {
  margin-top: 20px;
}

.info-section {
  margin-bottom: 15px;
}

.success {
  color: green;
}

.error {
  color: red;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* 优化输入框样式 */
.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

button.modal-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button.modal-button:hover {
  background-color: #0056b3;
}

button.modal-button:disabled {
  background-color: #007bff;
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
