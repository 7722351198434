<template>
  <header class="header" :class="{ 'header-shift': isDrawerOpen }">
    <button class="menu-button" @click="toggleDrawer">
      <div class="menu-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <h1 class="title">主页</h1>
  </header>
</template>

<script>
export default {
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleDrawer'],
  methods: {
    toggleDrawer() {
      this.$emit('toggleDrawer');
    },
  },
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: rgb(177, 174, 174); /* Changed to a darker color */
  backdrop-filter: blur(10px);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increased shadow for better visibility */
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.header-shift {
  transform: translateX(130px);
}

.title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}

.menu-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
}

.menu-icon span {
  display: block;
  height: 4px;
  width: 100%;
  background: #f0f0f0; /* Changed to a lighter color for better contrast */
  border-radius: 2px;
  transition: transform 0.3s, opacity 0.3s;
}
</style>
