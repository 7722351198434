<template>
  <div class="drawer-wrapper">
    <div class="drawer" :class="{ 'drawer-open': isOpen }">
      <div class="drawer-content">
        <slot></slot>
        <div class="imh">
          主页
          <br />
          <hr />
        </div>
        <div class="drawer-buttons">
          <button @click="button1">查询
          身高</button>
          <button @click="button2">购买
            卡券</button>
          <button @click="button3">点我
            加群</button>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="backdrop" @click="handleBackdropClick"></div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:isOpen'],
  methods: {
    closeDrawer() {
      this.$emit('update:isOpen', false);
    },
    handleBackdropClick(event) {
      if (event.target === event.currentTarget) {
        this.closeDrawer();
      }
    },
    button1() {
      this.$router.push('/height');
      this.closeDrawer();
    },
    button2() {
      this.closeDrawer();
      // 打开QQ群链接
      window.open('https://c.fakamiao.shop/shopDetail/2280', '_blank');
    },
    button3() {
      this.closeDrawer();
      // 打开QQ群链接
      window.open('http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=GspZImdNAl4gN19Xoirj7hzINCsrdD7a&authKey=MsMIr8gEgGvjMUenHcXprJjwvZIoDAfuyYYoeajTTrX1%2BjvD3Mrx8VibhwEapeGW&noverify=0&group_code=112314853', '_blank');
    }
  },
}
</script>

<style scoped>
.drawer-wrapper {
  position: relative;
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 130px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1000;
}

.drawer-open {
  transform: translateX(0);
}

.drawer-content {
  padding: 20px;
}

.drawer-buttons {
  margin-top: 20px;
}

.drawer-buttons button {
  display: block;
  margin-bottom: 10px;
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, transform 0.2s;
}

.drawer-buttons button:hover {
  background: linear-gradient(0deg, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%);
  transform: translateY(-2px);
}

.drawer-buttons button:active {
  background: linear-gradient(0deg, #fbc2eb 0%, #a6c1ee 100%);
  transform: translateY(0);
}

.imh {
  text-align: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999;
}
</style>
