<!-- App.vue -->
<script setup>
import {useRouter} from 'vue-router'
import { onMounted, ref } from 'vue';
import Header from './components/HeaderComponent.vue';
import BlurDrawer from './components/BlurDrawer.vue';


const isDrawerOpen = ref(false);

const toggleDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value;
};
const router = useRouter()
onMounted(()=>{
  router.push('/height')
})

</script>

<template>
  <div class="app">
    <Header @toggleDrawer="toggleDrawer" :isDrawerOpen="isDrawerOpen" />
    <BlurDrawer v-model:isOpen="isDrawerOpen" />
    <router-view/>
  </div>
</template>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}


</style>
